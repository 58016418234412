import { getRouteUrl, ROUTES } from '@router';
import { getStatusNotificationTranslations, submitForm } from '@services';
import {
  AdminLicenseCard,
  AdminLicenseTranslations,
  Avatar,
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  Chevron,
  Col,
  colors,
  common,
  display,
  flex,
  Icon,
  Input,
  offsets,
  openStatusNotification,
  Row,
  SelectItem,
  Toggle,
  Typography,
  validate,
  ValidateTypes
} from '@xq/ui-kit';
import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CreateEditUserService } from './create-edit-user-service';
import { License, LicenseInfo, User, UserLicense } from './dataTypes';
import styles from './CreateEditUser.module.scss';
import {
  getUserLicenseFromFullLicenseData,
  mapLicenses
} from '@pages/Users/CreateEditUser/utils';
import { UserContext, UserContextData } from '@context';
import { handleTranslationErrorFromStatus } from '@services/TranslationErrorFromStatus';

export const CreateEditUser: FC = () => {
  const { t } = useTranslation();
  const service: CreateEditUserService = new CreateEditUserService();

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const userContext: UserContextData = useContext(UserContext);

  const [isCreatePage, setIsCreatePage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string | number>('');
  const [firstName, setFirstName] = useState<string | number>('');
  const [lastName, setLastName] = useState<string | number>('');
  const [avatar, setAvatar] = useState<string>('');
  const [isOrganizationAdmin, setIsOrganizationAdmin] =
    useState<boolean>(false);
  const [isExternal, setIsExternal] = useState<boolean>(false);
  const [fromAzure, setFromAzure] = useState<boolean>(null);

  /* Information about organization licenses: description, type, etc. */
  const [licensesInfo, setLicensesInfo] = useState<LicenseInfo[]>([]);
  /* In which licenses with which role the user is included */
  const [userLicenses, setUserLicenses] = useState<UserLicense[]>([]);
  /* Mapped data for showing license and change data */
  const [licenses, setLicenses] = useState<License[]>([]);
  const [isEmailValidationError, setIsEmailValidationError] = useState(false);

  const isFieldsFilled = useMemo(() => {
    return Boolean(email && lastName && firstName);
  }, [email, lastName, firstName]);

  async function fetchData() {
    /* Common data for the both routes (create and edit) */
    try {
      const response = await service.fetchData();
      setLicensesInfo(response?.licenses);
      // setOrganizationName(response?.organizationName);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function fetchUserData(userId: string) {
    try {
      const response = await service.fetchUser(userId);
      setEmail(response?.email);
      setFirstName(response?.firstName);
      setLastName(response?.lastName);
      setAvatar(response?.avatar);
      setIsOrganizationAdmin(response?.isOrganizationAdmin);
      setIsExternal(response?.isExternal);
      setUserLicenses(response?.attachToLicenses);
      setFromAzure(response?.fromAzure);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function save() {
    const isEmailValidated = validate(ValidateTypes.email, email);
    setIsEmailValidationError(!isEmailValidated);

    if (!isEmailValidated) {
      return;
    }

    setIsLoading(true);

    const user: User = {
      email: String(email),
      firstName: String(firstName),
      lastName: String(lastName),
      isOrganizationAdmin,
      isExternal,
      attachToLicenses: getUserLicenseFromFullLicenseData(licenses)
    };

    if (params.userId) {
      user.uuid = params.userId;
    }

    try {
      await service.save(user);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });

      navigate(getRouteUrl(ROUTES.USERS.USERS));
    } catch (error) {
      const localizedErrorMessage = handleTranslationErrorFromStatus(t, error);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: localizedErrorMessage
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  const cancel = () => {
    navigate(getRouteUrl(ROUTES.USERS.USERS));
  };

  const selectRole = (role: SelectItem, license: License) => {
    const newLicenses = licenses.map((el) => {
      if (el.uuid === license.uuid) {
        el.selectedRole = role;
      }
      return el;
    });
    setLicenses(newLicenses);
  };

  const setIsActive = (newValue: boolean, license: License) => {
    const newLicenses = licenses?.map((el) => {
      if (el.uuid === license.uuid) {
        if (el.isActive) {
          el.selectedRole = null;
        }
        el.isActive = newValue;
      }

      return el;
    });
    setLicenses(newLicenses);
  };

  useEffect(() => {
    /* set full license info for the card */
    setLicenses(mapLicenses(licensesInfo, userLicenses));
  }, [userLicenses, licensesInfo]);

  useEffect(() => {
    fetchData();
    const userId = params.userId;

    setIsCreatePage(!userId);

    if (userId) {
      fetchUserData(userId);
    }
  }, [location.pathname, params.userId]);

  const breadcrumbs: BreadcrumbsItem[] = useMemo(
    () => [
      {
        label: t(ROUTES.USERS.USERS),
        url: getRouteUrl(ROUTES.USERS.USERS)
      }
    ],
    []
  );

  /* translations */
  const adminLicenseTranslations: AdminLicenseTranslations = {
    role: t('uiKit.role'),
    expiryDate: t('uiKit.expiryDate'),
    trialVersion: t('uiKit.trialVersion')
  };

  return (
    <Fragment>
      <Row cols={10}>
        <Breadcrumbs
          NavLink={NavLink}
          className={'breadcrumbs'}
          items={breadcrumbs}
        />

        <Col col={9}>
          <Typography element="div" variant="h2" className={offsets['mb-40']}>
            {isCreatePage
              ? t('organizations.createUser')
              : t('organizations.editUser')}
          </Typography>
        </Col>

        {!isCreatePage && (
          <Col col={9} className={styles['user-info']}>
            <Avatar
              disabled
              image={avatar}
              name={`${firstName} ${lastName}`}
              size={'large'}
              className={offsets['mr-20']}
            />

            <div>
              <div
                className={cn(
                  display['d-flex'],
                  flex['align-items-center'],
                  offsets['mb-4']
                )}
              >
                <Typography element="div" variant="body-2">
                  {firstName} {lastName}
                </Typography>

                {fromAzure && (
                  <Chevron
                    className={offsets['ml-8']}
                    backgroundColor={colors.blue50Color}
                  >
                    Azure AD
                  </Chevron>
                )}
              </div>

              <Typography
                element="div"
                className={cn(offsets['mb-8'], common['no-text-transform'])}
                variant="body-1"
              >
                {email}
              </Typography>
              {userContext?.organizations?.length > 0 && (
                <Typography element="div" variant="body-1">
                  {userContext?.organizations[0]?.name}
                </Typography>
              )}
            </div>
          </Col>
        )}

        <form onSubmit={submitForm} className={styles.main}>
          <div className={styles.form}>
            <div>
              <Input
                label={t('common.email')}
                value={email}
                type="email"
                inputMode="email"
                disabled={isLoading || fromAzure}
                onChange={setEmail}
                required
                className={offsets['mb-20']}
                errorMessage={
                  isEmailValidationError
                    ? t('notifications.emailIsNotValid')
                    : ''
                }
              />
              <Input
                label={t('common.firstName')}
                value={firstName}
                type="text"
                disabled={isLoading || fromAzure}
                onChange={setFirstName}
                required
                className={offsets['mb-20']}
              />
              <Input
                label={t('common.lastName')}
                value={lastName}
                type="text"
                disabled={isLoading || fromAzure}
                onChange={setLastName}
                required
                className={offsets['mb-40']}
              />

              <div
                className={cn(
                  display['d-flex'],
                  flex['align-items-center'],
                  offsets['mb-40']
                )}
              >
                <Typography className={offsets['mr-12']} variant="body-1">
                  {t('organizations.organizationAdmin')}
                </Typography>
                <Toggle
                  label={'Organization admin'}
                  checked={isOrganizationAdmin}
                  onChange={setIsOrganizationAdmin}
                  disabled={isLoading}
                />
              </div>

              <div
                className={cn(
                  display['d-flex'],
                  flex['align-items-center'],
                  offsets['mb-40']
                )}
              >
                <Typography className={offsets['mr-12']} variant="body-1">
                  {t('organizations.externalUser')}
                </Typography>
                <Toggle
                  label={'External user'}
                  checked={isExternal}
                  onChange={setIsExternal}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
          <div className={styles.license}>
            <Typography
              element="div"
              color={colors.gray60Color}
              className={offsets['mb-8']}
              variant="system-heading"
            >
              {t('common.licenses')}
            </Typography>

            {licenses && licenses?.length === 0 && (
              <Icon
                name="inbox"
                size="xxl"
                color="light-grey"
                className={offsets['mb-20']}
              />
            )}

            {licenses?.length > 0 &&
              licenses?.map((license, key) => {
                return (
                  <AdminLicenseCard
                    key={key}
                    translations={adminLicenseTranslations}
                    app={license.app}
                    description={license?.description}
                    roles={license?.roles}
                    setSelectedRole={(role) => selectRole(role, license)}
                    selectedRole={license.selectedRole}
                    isTrial={license?.isTrial}
                    expiryDate={license?.expiryDate}
                    className={offsets['mb-20']}
                    disabled={isLoading || !isFieldsFilled}
                    isActive={license?.isActive}
                    setIsActive={(newValue) => setIsActive(newValue, license)}
                    licenseType={license?.licenceType}
                  />
                );
              })}
          </div>
          <div className={styles.buttons}>
            <div className={cn(display['d-flex'], flex['align-items-center'])}>
              <Button
                buttonType={'submit'}
                disabled={!isFieldsFilled}
                onClick={save}
                className={offsets['mr-20']}
                isLoading={isLoading}
              >
                {t('common.save')}
              </Button>
              <Button onClick={cancel} type="secondary" disabled={isLoading}>
                {t('common.cancel')}
              </Button>
            </div>
          </div>
        </form>
      </Row>
    </Fragment>
  );
};

CreateEditUser.displayName = 'CreateEditUser';
