import { wait, handleBackendError, adminApiConfiguration } from '@services';
import { ContactPerson } from './dataTypes';
import { ContactPersonCreatePageApi } from '@xq/admin-panel-gateway-frontend-client';

export interface CreateContactPersonService {
  create(contactPerson: ContactPerson): Promise<void>;
}

const createContactPersonGateway = new ContactPersonCreatePageApi(
  adminApiConfiguration
);

export class CreateContactPersonService implements CreateContactPersonService {
  async create(contactPerson: ContactPerson): Promise<void> {
    try {
      await createContactPersonGateway.contactPersonCreatePageControllerCreate({
        contactPersonDTORequestCreate: {
          name: contactPerson.name,
          position: contactPerson.position,
          email: contactPerson.email,
          phone: contactPerson.phone,
          comment: contactPerson.comments
        }
      });
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.create.name);
    }
  }
}

export class CreateContactPersonServiceMock
  implements CreateContactPersonService
{
  async create(contactPerson: ContactPerson): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.create.name);
    }
  }
}
