import { Row, Col, Typography, EmptyState } from '@xq/ui-kit';
import React, { FC, Fragment, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ORGANIZATION_SIDEMENUS } from '@services';
import { getRouteUrl, ROUTES } from '@router';
import { SidemenuContext, SidemenuContextData } from '@context';

export const Licenses: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);

  useEffect(() => {
    if (sidemenuContext?.licenses?.length > 0) {
      navigate(
        getRouteUrl(ROUTES.LICENSES.LICENSE, {
          licenseId: sidemenuContext.licenses[0].uuid
        })
      );
    }
  }, [sidemenuContext?.licenses]);

  /** Sidemenu context */
  useEffect(() => {
    sidemenuContext.setActiveMenu(ORGANIZATION_SIDEMENUS.ORGANIZATION_LICENSES);
  }, [sidemenuContext]);

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={9} className="heading">
          <Typography element="div" variant="h2">
            {t('common.licenses')}
          </Typography>
        </Col>

        {sidemenuContext?.licenses?.length === 0 && (
          <EmptyState
            heading={t('uiKit.oopsItIsEmpty')}
            description={t('alerts.looksLikeTheOrganizationHasNoLicenses')}
            isCentered={true}
          />
        )}
      </Row>
    </Fragment>
  );
};

Licenses.displayName = 'Licenses';
