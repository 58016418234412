import { adminApiConfiguration, handleBackendError, wait } from '@services';
import { OrganizationName } from 'interfaces';
import { SideMenuApi } from '@xq/admin-panel-gateway-frontend-client';

export interface SidemenuLayoutLicense {
  uuid: string;
  title: string;
  hasErrors?: boolean;
}

export interface SidemenuLayoutInformation {
  organizations?: OrganizationName[];
  currentOrganization?: OrganizationName;
  licenses?: SidemenuLayoutLicense[];
}

export interface SidemenuLayoutService {
  fetchData(): Promise<SidemenuLayoutInformation>;
}

const sidemenuLayoutGateway = new SideMenuApi(adminApiConfiguration);

export class SidemenuLayoutServiceApi implements SidemenuLayoutService {
  async fetchData(): Promise<SidemenuLayoutInformation> {
    try {
      const { currentOrganization, licenses } =
        await sidemenuLayoutGateway.menuControllerGetSideMenuData();

      return {
        currentOrganization,
        licenses
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }
}

const sidemenuLayoutInformationMock: SidemenuLayoutInformation = {
  currentOrganization: {
    uuid: '00000000-0000-0000-0000-000000000000',
    name: 'Exquance',
    countryIso3: 'FIN'
  },
  licenses: [
    { uuid: '1', title: 'Fundy' },
    {
      uuid: '2',
      title: 'ModelTree: Investment management',
      hasErrors: true
    }
  ]
};

export class SidemenuLayoutServiceMock implements SidemenuLayoutService {
  async fetchData(): Promise<SidemenuLayoutInformation> {
    try {
      await wait(100);
      return sidemenuLayoutInformationMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }
}
