import { config } from '@config';
import { getRouteUrl } from '@router';
import { BreadcrumbsDropdown, CountryIso3, Locales } from '@xq/ui-kit';
import { OrganizationName } from 'interfaces/base-interfaces';

export const generateCompaniesList = (
  organizations: OrganizationName[],
  route: string
): BreadcrumbsDropdown[] => {
  return organizations?.map((organization) => {
    return {
      label: organization.name,
      url: getRouteUrl(route, { id: organization.uuid }),
      countryIso3: organization?.countryIso3 as CountryIso3
    };
  });
};

export const getCurrentLanguage = (): Locales => {
  let language = window.localStorage.getItem('lang');

  if (!language) {
    language = config.defaultLang;
  }
  return language as Locales;
};
