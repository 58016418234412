import React from 'react';
import {
  NotFound,
  Organization,
  InternalServerError,
  Users,
  CreateEditUser,
  Licenses,
  License,
  CreateContactPerson,
  EditContactPerson,
  ContactPersons,
  SecuritySettings,
  Forbidden
} from '@pages';
import { Layout, SidemenuLayout } from '@layouts';
import { Outlet, RouteObject } from 'react-router-dom';
import { LayoutContainer, offsets } from '@xq/ui-kit';

export const SECTION_URL = {
  ORGANIZATION: 'organization',
  USERS: 'users',
  LICENSES: 'licenses'
};

export const ROUTES = {
  HOME: 'routes.mainInformation',
  ORGANIZATION: {
    ORGANIZATION: 'routes.organizations.organization',
    ORGANIZATION_INFO: 'routes.organizations.organization-info',
    CONTACT_PERSONS: 'routes.organizations.contact-persons',
    CREATE_CONTACT_PERSON: 'routes.organizations.create-contact-person',
    EDIT_CONTACT_PERSON: 'routes.organizations.edit-contact-person',
    SECURITY_SETTINGS: 'organizations.securitySettings'
  },
  USERS: {
    CREATE_USER: 'routes.organizations.create-user',
    EDIT_USER: 'routes.organizations.edit-user',
    USERS: 'routes.organizations.users'
  },
  LICENSES: {
    LICENSES: 'routes.organizations.licenses',
    LICENSE: 'routes.organizations.license'
  },
  ERRORS: {
    FORBIDDEN: 'routes.errors.forbidden'
  }
};

export const getRouteUrl = (name: string, params?: Record<string, string>) => {
  switch (name) {
    case ROUTES.USERS.USERS: {
      return `/${SECTION_URL.USERS}`;
    }
    case ROUTES.USERS.CREATE_USER: {
      return `/${SECTION_URL.USERS}/create`;
    }
    case ROUTES.USERS.EDIT_USER: {
      return `/${SECTION_URL.USERS}/${params.userId}`;
    }
    case ROUTES.ORGANIZATION.ORGANIZATION: {
      return `/`;
    }
    case ROUTES.ORGANIZATION.CONTACT_PERSONS: {
      return `/${SECTION_URL.ORGANIZATION}/contact-persons`;
    }
    case ROUTES.ORGANIZATION.CREATE_CONTACT_PERSON: {
      return `/${SECTION_URL.ORGANIZATION}/contact-persons/create`;
    }
    case ROUTES.ORGANIZATION.EDIT_CONTACT_PERSON: {
      return `/${SECTION_URL.ORGANIZATION}/contact-persons/${params.contactPersonId}`;
    }
    case ROUTES.ORGANIZATION.SECURITY_SETTINGS: {
      return `/${SECTION_URL.ORGANIZATION}/security-settings`;
    }
    case ROUTES.LICENSES.LICENSES: {
      return `/${SECTION_URL.LICENSES}`;
    }
    case ROUTES.LICENSES.LICENSE: {
      return `/${SECTION_URL.LICENSES}/${params.licenseId}`;
    }

    default: {
      return '/';
    }
  }
};

export const routes: RouteObject[] = [
  {
    id: ROUTES.HOME,
    path: '/',
    element: <Layout />,
    children: [
      {
        id: ROUTES.HOME,
        path: '/',
        element: (
          <LayoutContainer className={offsets['mt-60']}>
            <Outlet />
          </LayoutContainer>
        ),
        children: [
          {
            id: ROUTES.USERS.USERS,
            path: `/${SECTION_URL.USERS}`,
            children: [
              {
                id: ROUTES.USERS.USERS,
                path: '',
                index: true,
                element: <Users />
              },
              {
                id: ROUTES.USERS.CREATE_USER,
                path: 'create',
                element: <CreateEditUser />
              },
              {
                id: ROUTES.USERS.EDIT_USER,
                path: ':userId',
                element: <CreateEditUser />
              }
            ]
          }
        ]
      },
      {
        id: ROUTES.HOME,
        path: '/',
        element: <SidemenuLayout />,
        children: [
          {
            id: ROUTES.ORGANIZATION.ORGANIZATION,
            path: '',
            children: [
              {
                id: ROUTES.ORGANIZATION.ORGANIZATION,
                path: '',
                index: true,
                element: <Organization />
              },
              {
                id: ROUTES.ORGANIZATION.CONTACT_PERSONS,
                path: `${SECTION_URL.ORGANIZATION}/contact-persons`,
                index: true,
                element: <ContactPersons />
              },
              {
                id: ROUTES.ORGANIZATION.CREATE_CONTACT_PERSON,
                path: `${SECTION_URL.ORGANIZATION}/contact-persons/create`,
                element: <CreateContactPerson />
              },
              {
                id: ROUTES.ORGANIZATION.EDIT_CONTACT_PERSON,
                path: `${SECTION_URL.ORGANIZATION}/contact-persons/:contactPersonId`,
                element: <EditContactPerson />
              },
              {
                id: ROUTES.ORGANIZATION.SECURITY_SETTINGS,
                path: `${SECTION_URL.ORGANIZATION}/security-settings`,
                element: <SecuritySettings />
              }
            ]
          },
          {
            id: ROUTES.LICENSES.LICENSES,
            path: `/${SECTION_URL.LICENSES}`,
            children: [
              {
                id: ROUTES.LICENSES.LICENSES,
                path: '',
                element: <Licenses />
              },
              {
                id: ROUTES.LICENSES.LICENSE,
                path: ':licenseId',
                element: <License />
              }
            ]
          }
        ]
      }
    ]
  },
  {
    id: 'notFound',
    path: '*',
    element: <NotFound />
  },
  {
    id: 'internalError',
    path: 'internal-error',
    element: <InternalServerError />
  },
  {
    id: ROUTES.ERRORS.FORBIDDEN,
    path: 'forbidden',
    element: <Forbidden />
  }
];
