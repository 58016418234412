import { SelectItem, TableFilter, TableFilterGroup } from '@xq/ui-kit';
import {
  adminApiConfiguration,
  handleBackendError,
  mapFiltersFromGatewayToUI,
  wait
} from '@services';
import { LicensePageData, LicenseUser } from '@pages';
import { ListMetaInfo, ListParams, TableFilterValues } from 'interfaces';
import {
  GatewayListFiltersDTOResponse,
  LicenseAttachedUserListSortOptionsDTOFieldNameEnum,
  LicenseAttachedUserListSortOptionsDTOOrderEnum,
  LicenseDetachedUserListSortOptionsDTOFieldNameEnum,
  LicenseDetachedUserListSortOptionsDTOOrderEnum,
  LicenseInfoPageApi,
  LicenseUserAttachDTOResponse
} from '@xq/admin-panel-gateway-frontend-client';
import { filterValues } from '@pages/Users/Users/users-service';

export interface LicenseData {
  meta: ListMetaInfo;
  items: LicenseUser[];
}

export interface LicenseService {
  fetchData(licenseUuid: string): Promise<LicensePageData>;

  fetchFilters(licenseUuid: string): Promise<TableFilterValues>;

  fetchActiveUsers(
    licenseUuid: string,
    params?: ListParams
  ): Promise<LicenseData>;

  fetchInactiveUsers(
    licenseUuid: string,
    params?: ListParams
  ): Promise<LicenseData>;

  detachUser(licenseUuid: string, userUuid: string): Promise<void>;

  attachUser(
    licenseUuid: string,
    userUuid: string
  ): Promise<LicenseUserAttachDTOResponse>;

  changeUserRole(
    licenseUuid: string,
    userUuid: string,
    newRoleUuid: string
  ): Promise<void>;
}

const licenseGateway = new LicenseInfoPageApi(adminApiConfiguration);

export class LicenseServiceApi implements LicenseService {
  async fetchActiveUsers(
    licenseUuid: string,
    params?: ListParams
  ): Promise<LicenseData> {
    try {
      return await licenseGateway.licenseInfoPageControllerGetAttachedUsers({
        licenseUuid: licenseUuid,

        searchString: params?.searchValue,
        licenseAttachedUserListOptionsDTORequest: {
          pagination: {
            page: params?.page,
            limit: params?.limit
          },
          filters: params.filters,
          sort: [
            {
              order:
                params?.sortOrder as LicenseAttachedUserListSortOptionsDTOOrderEnum,
              fieldName:
                params?.sortBy as LicenseAttachedUserListSortOptionsDTOFieldNameEnum
            }
          ]
        }
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchActiveUsers.name
      );
    }
  }

  async fetchInactiveUsers(
    licenseUuid: string,
    params?: ListParams
  ): Promise<LicenseData> {
    try {
      return await licenseGateway.licenseInfoPageControllerGetDetachedUsers({
        licenseUuid: licenseUuid,
        searchString: params?.searchValue,
        licenseDetachedUserListOptionsDTORequest: {
          pagination: {
            page: params?.page,
            limit: params?.limit
          },
          filters: params.filters,
          sort: [
            {
              order:
                params?.sortOrder as LicenseDetachedUserListSortOptionsDTOOrderEnum,
              fieldName:
                params?.sortBy as LicenseDetachedUserListSortOptionsDTOFieldNameEnum
            }
          ]
        }
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchInactiveUsers.name
      );
    }
  }

  async fetchData(licenseUuid: string): Promise<LicensePageData> {
    try {
      return await licenseGateway.licenseInfoPageControllerGetPageData({
        licenseUuid
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async fetchFilters(licenseUuid: string): Promise<TableFilterValues> {
    try {
      const filters: GatewayListFiltersDTOResponse =
        await licenseGateway.licenseInfoPageControllerGetUserListFilters({
          licenseUuid
        });

      const mappedFilters: TableFilter[] = mapFiltersFromGatewayToUI(
        filters.filters
      );

      const mappedGroups: TableFilterGroup[] = filters.groups?.map((group) => {
        return {
          id: group.groupId,
          groupTitle: group.groupTitle
        };
      });

      return {
        filters: mappedFilters,
        groups: mappedGroups
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchFilters.name
      );
    }
  }

  async detachUser(licenseUuid: string, userUuid: string): Promise<void> {
    try {
      await licenseGateway.licenseInfoPageControllerDetachUser({
        userUuid,
        licenseUuid
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.detachUser.name
      );
    }
  }

  async attachUser(
    licenseUuid: string,
    userUuid: string
  ): Promise<LicenseUserAttachDTOResponse> {
    try {
      return await licenseGateway.licenseInfoPageControllerAttachUser({
        userUuid,
        licenseUuid
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.attachUser.name
      );
    }
  }

  async changeUserRole(
    licenseUuid: string,
    userUuid: string,
    newRoleUuid: string
  ): Promise<void> {
    try {
      await licenseGateway.licenseInfoPageControllerUpdateUserRole({
        roleUuid: newRoleUuid,
        userUuid,
        licenseUuid
      });
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.changeUserRole.name
      );
    }
  }
}

const roles: SelectItem[] = [
  { value: '1', label: 'Valuator' },
  { value: '2', label: 'Admin' }
];

const activeUsersMock: LicenseUser[] = [
  {
    uuid: '1',
    avatar: '',
    fullname: 'Charles Hunt',
    email: 'charles.hunt@2ns.com',
    isBlocked: true,
    isOrganizationAdmin: true,
    attachDate: new Date(2023, 1, 4, 20, 36, 42),
    role: roles[0]
  },
  {
    uuid: '2',
    avatar: '',
    fullname: 'Courtney Scott',
    email: 'courtney.scott@2ns.com',
    isBlocked: false,
    isOrganizationAdmin: false,
    attachDate: new Date(2023, 1, 6, 10, 36, 42),
    role: roles[1]
  },
  {
    uuid: '3',
    avatar: '',
    fullname: 'Craig Anderson',
    email: 'craig.anderson@2ns.com',
    isBlocked: true,
    isOrganizationAdmin: false,
    attachDate: new Date(2023, 1, 20, 20, 36, 42),
    role: roles[0]
  },
  {
    uuid: '4',
    avatar: '',
    fullname: 'John Thompson',
    email: 'john.thompson@2ns.com',
    isBlocked: true,
    isOrganizationAdmin: false,
    attachDate: new Date(2023, 1, 24, 20, 36, 42),
    role: roles[0]
  },
  {
    uuid: '5',
    avatar: '',
    fullname: 'Donna Caldwell',
    email: 'donna.caldwell@2ns.com',
    isBlocked: true,
    isOrganizationAdmin: false,
    attachDate: new Date(2023, 1, 26, 20, 36, 42),
    role: roles[1]
  },
  {
    uuid: '6',
    avatar: '',
    fullname: 'Horatio Morrison',
    email: 'horatio.morrison@2ns.com',
    isBlocked: true,
    isOrganizationAdmin: false,
    attachDate: new Date(2023, 2, 1, 21, 26, 42),
    role: roles[0]
  },
  {
    uuid: '7',
    avatar: '',
    fullname: 'Jackie Walsh',
    email: 'jackie.walsh@2ns.com',
    isBlocked: true,
    isOrganizationAdmin: false,
    attachDate: new Date(2023, 2, 2, 11, 3, 4),
    role: roles[0]
  },
  {
    uuid: '8',
    avatar: '',
    fullname: 'John Thompson',
    email: 'john.thompson@2ns.com',
    isBlocked: true,
    isOrganizationAdmin: true,
    attachDate: new Date(2023, 2, 3, 15, 13, 40),
    role: roles[1]
  },
  {
    uuid: '9',
    avatar: '',
    fullname: 'Jayson Randall',
    email: 'jayson.randall@2ns.com',
    isBlocked: true,
    isOrganizationAdmin: false,
    attachDate: new Date(2023, 2, 4, 12, 2, 32),
    role: roles[1]
  }
];

const usersMetaInfoMock: ListMetaInfo = {
  totalItems: 50,
  totalPages: 5,
  itemsPerPage: 10,
  currentPage: 1,
  itemCount: 50
};

const activeUsersData: LicenseData = {
  items: activeUsersMock,
  meta: usersMetaInfoMock
};

const inactiveUsersMock: LicenseUser[] = [
  {
    uuid: '10',
    avatar: '',
    fullname: 'Shana Brooks',
    email: 'shana.brooks@2ns.com',
    isBlocked: true,
    isOrganizationAdmin: false
  },
  {
    uuid: '11',
    avatar: '',
    fullname: 'Megan Garcia',
    email: 'megan.garcia@2ns.com',
    isBlocked: true,
    isOrganizationAdmin: false
  },
  {
    uuid: '12',
    avatar: '',
    fullname: 'Franklin Stephen Heath',
    email: 'franklin.stephen.heath@2ns.сom',
    isBlocked: true,
    isOrganizationAdmin: false
  }
];

const inactiveUsersData = {
  items: inactiveUsersMock,
  meta: usersMetaInfoMock
};

const pageDataMock: LicensePageData = {
  title: 'ModelTree: Investment management',
  createdAt: new Date(2023, 2, 4),
  expireAt: new Date('2023-12-19T18:32:04.000Z'),
  activeUsers: 20,
  licenseFeatures: [
    'EWV approach',
    'Report builder',
    'Integrations tab',
    'ERRV 303',
    'New integrations tool',
    'Map layers viewer'
  ],
  isTrial: false,
  isSuspended: false,
  isExpired: false,
  roles: roles
};

export class LicenseServiceMock implements LicenseService {
  async fetchActiveUsers(
    licenseUuid: string,
    params?: ListParams
  ): Promise<LicenseData> {
    try {
      await wait(1000);
      return activeUsersData;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchActiveUsers.name
      );
    }
  }

  async fetchInactiveUsers(
    licenseUuid: string,
    params?: ListParams
  ): Promise<LicenseData> {
    try {
      await wait(1000);
      return inactiveUsersData;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchInactiveUsers.name
      );
    }
  }

  async fetchData(licenseUuid: string): Promise<LicensePageData> {
    try {
      await wait(1000);
      return pageDataMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async fetchFilters(licenseUuid: string): Promise<TableFilterValues> {
    try {
      await wait(1000);
      return filterValues;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchFilters.name
      );
    }
  }

  async detachUser(licenseUuid: string, userUuid: string): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.detachUser.name
      );
    }
  }

  async attachUser(
    licenseUuid: string,
    userUuid: string
  ): Promise<LicenseUserAttachDTOResponse> {
    try {
      await wait(1000);
      return {
        attachDate: new Date('2023-10-01T09:04:05.578Z'),
        role: {
          label: 'Valuator',
          value: '1'
        }
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.attachUser.name
      );
    }
  }

  async changeUserRole(
    licenseUuid: string,
    userUuid: string,
    newRoleUuid: string
  ): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.changeUserRole.name
      );
    }
  }
}
