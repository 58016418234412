import { adminApiConfiguration, handleBackendError, wait } from '@services';
import { ContactPerson } from './dataTypes';
import { ContactPersonEditPageApi } from '@xq/admin-panel-gateway-frontend-client';

export interface EditContactPersonService {
  fetchData(contactPersonUuid: string): Promise<ContactPerson>;

  save(contactPerson: ContactPerson): Promise<void>;
}

const editContactPersonGateway = new ContactPersonEditPageApi(
  adminApiConfiguration
);

export class EditContactPersonService implements EditContactPersonService {
  async fetchData(contactPersonUuid: string): Promise<ContactPerson> {
    try {
      const response =
        await editContactPersonGateway.contactPersonEditPageControllerGetPageData(
          {
            organizationContactPersonUuid: contactPersonUuid
          }
        );
      return response.contactPerson as ContactPerson;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(contactPerson: ContactPerson): Promise<void> {
    try {
      await editContactPersonGateway.contactPersonEditPageControllerUpdateContactPerson(
        {
          organizationContactPersonUuid: contactPerson.uuid,
          contactPersonDTORequestUpdate: {
            name: contactPerson.name,
            position: contactPerson.position || null,
            email: contactPerson.email,
            phone: contactPerson.phone || null,
            comment: contactPerson.comment || null
          }
        }
      );
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}

const contactPersonMock: ContactPerson = {
  uuid: '1',
  name: 'Alexey Vlasov',
  phone: '+79216486433',
  email: 'alexey.vlasov@exquance.com',
  position: 'IT Admin',
  comment:
    'Mattis massa sed ac, pellentesque massa congue luctus etiam faucibus'
};

export class EditContactPersonServiceMock implements EditContactPersonService {
  async fetchData(contactPersonUuid: string): Promise<ContactPerson> {
    try {
      await wait(1000);
      return contactPersonMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(contactPerson: ContactPerson): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}
