import { config } from '@config';
import {
  generateHeaderAppSwitcherItems,
  HeaderAppItem,
  HeaderAppSwitcherUrls
} from '@xq/ui-kit';

export const generateHeaderAppItems = (
  isOrganizationAdmin?: boolean,
  isXQAdmin?: boolean
): HeaderAppItem[] => {
  const headerAppUrls: HeaderAppSwitcherUrls = {
    modelTreeFrontendUrl: config.modelTreeFrontendUrl,
    insightsFrontendUrl: config.insightsFrontendUrl,
    administrationFrontendUrl: config.administrationFrontendUrl,
    omniFrontendUrl: config.omniFrontendUrl
  };

  return generateHeaderAppSwitcherItems(
    headerAppUrls,
    isOrganizationAdmin,
    isXQAdmin
  );
};

export const redirectToSSOLoginPage = () => {
  const ssoUrl = `${config.ssoFrontendLoginUrl}?redirectUrl=${window.location.href}`;
  window.location.replace(ssoUrl);
};
