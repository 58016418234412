import React from 'react';
import { OrganizationItem } from '@xq/ui-kit';

export interface UserContextData {
  firstName: string;
  lastName: string;
  avatar: string;
  languageIso2: string;
  organizations: OrganizationItem[];
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setAvatar: (value: string) => void;
  setLanguageIso2: (value: string) => void;
}

const userContextData: UserContextData = {
  firstName: '',
  lastName: '',
  avatar: '',
  languageIso2: '',
  organizations: [],
  setFirstName: () => null,
  setLastName: () => null,
  setAvatar: () => null,
  setLanguageIso2: () => null
};

export const UserContext =
  React.createContext<UserContextData>(userContextData);
