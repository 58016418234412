import {
  Row,
  Col,
  Typography,
  Button,
  ContactPerson as ContactPersonCard,
  Alert,
  EmptyState,
  openStatusNotification
} from '@xq/ui-kit';
import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import styles from './ContactPersons.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  getStatusNotificationTranslations,
  ORGANIZATION_SIDEMENUS
} from '@services';
import { getRouteUrl, ROUTES } from '@router';
import { SidemenuContext, SidemenuContextData } from '@context';
import { ContactPersonsService } from './contact-persons-service';
import { ContactPerson } from '@pages/Organizations/ContactPersons/dataTypes';

export const ContactPersons: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);
  const service: ContactPersonsService = new ContactPersonsService();

  const [contactPersons, setContactPersons] = useState<ContactPerson[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');
  const [contactPersonToDelete, setContactPersonToDelete] =
    useState<ContactPerson>(null);

  async function fetchData() {
    try {
      setIsLoading(true);
      const response = await service.fetchData();
      setContactPersons(response?.contactPersons);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const redirectToEditContactPerson = (contactPerson: ContactPerson) => {
    navigate(
      getRouteUrl(ROUTES.ORGANIZATION.EDIT_CONTACT_PERSON, {
        contactPersonId: contactPerson.uuid
      })
    );
  };

  async function createContactPerson() {
    navigate(getRouteUrl(ROUTES.ORGANIZATION.CREATE_CONTACT_PERSON));
  }

  async function deleteContactPerson(contactPerson: ContactPerson) {
    try {
      await service.deleteContactPerson(contactPerson.uuid);
      const updatedContactPersons = contactPersons?.filter(
        (el: ContactPerson) => el.uuid !== contactPerson.uuid
      );
      setContactPersons(updatedContactPersons);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: (
          <span>
            <strong>{contactPerson.name}</strong>{' '}
            {t('notifications.successfullyDeleted')}
          </span>
        )
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setAlertText('');
      setContactPersonToDelete(null);
    }
  }

  async function deleteContactPersonSubmit(contactPerson: ContactPerson) {
    setIsAlertOpen(true);
    setAlertText(
      `${t('alerts.areYouSureYouWantToDelete')} <b>${contactPerson.name}</b>?`
    );
    setContactPersonToDelete(contactPerson);
  }

  /** Sidemenu */
  useEffect(() => {
    sidemenuContext.setActiveMenu(
      ORGANIZATION_SIDEMENUS.ORGANIZATION_CONTACT_PERSONS
    );
  }, [sidemenuContext]);

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={9} className="heading">
          <Typography element="div" variant="h2">
            {t('organizations.contactPersons')}
          </Typography>
          {contactPersons && contactPersons.length > 0 && (
            <Button type="third" icon="user-plus" onClick={createContactPerson}>
              {t('common.create')}
            </Button>
          )}
        </Col>

        <Col col={9} className={styles.contacts}>
          {contactPersons &&
            contactPersons?.length > 0 &&
            contactPersons?.map((contactPerson: ContactPerson) => {
              return (
                <ContactPersonCard
                  key={contactPerson.uuid}
                  role={contactPerson.position}
                  name={contactPerson.name}
                  phoneNumber={contactPerson.phone}
                  email={contactPerson.email}
                  comments={contactPerson.comment}
                  isEdit={true}
                  onDelete={() => deleteContactPersonSubmit(contactPerson)}
                  onEdit={() => redirectToEditContactPerson(contactPerson)}
                  translations={{
                    seeDetails: t('uiKit.seeDetails'),
                    role: t('uiKit.role'),
                    name: t('common.name'),
                    phoneNumber: t('common.phoneNumber'),
                    email: t('common.email'),
                    comments: t('uiKit.comments'),
                    delete: t('common.delete'),
                    edit: t('common.edit')
                  }}
                />
              );
            })}

          {!isLoading && (!contactPersons || contactPersons.length === 0) && (
            <EmptyState
              heading={t('uiKit.oopsItIsEmpty')}
              description={t(
                'alerts.looksLikeTheOrganizationHasNoContactPersons'
              )}
              onClick={createContactPerson}
              buttonText={t('common.create')}
              buttonIcon={'user-plus'}
              isCentered={true}
            />
          )}
        </Col>
      </Row>
      <Alert
        isOpen={isAlertOpen}
        onSubmit={() => deleteContactPerson(contactPersonToDelete)}
        onClose={() => setIsAlertOpen(false)}
        cancelText={t('common.cancel')}
        submitText={t('common.submit')}
      >
        <div dangerouslySetInnerHTML={{ __html: alertText }} />
      </Alert>
    </Fragment>
  );
};

ContactPersons.displayName = 'ContactPersons';
