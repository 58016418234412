import {
  Alert,
  Button,
  Col,
  display,
  flex,
  Icon,
  Input,
  ListEditor,
  offsets,
  openStatusNotification,
  Row,
  Toggle,
  Tooltip,
  Typography
} from '@xq/ui-kit';
import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import styles from './SecuritySettings.module.scss';
import { useTranslation } from 'react-i18next';
import {
  getStatusNotificationTranslations,
  ORGANIZATION_SIDEMENUS,
  submitForm
} from '@services';
import {
  SecuritySettingsService,
  SecuritySettingsServiceApi
} from './security-settings-service';
import { SidemenuContext, SidemenuContextData } from '@context';
import hash from 'object-hash';
import cn from 'classnames';
import { SecuritySettingsData } from '@pages/Organizations/SecuritySettings/dataTypes';

export const SecuritySettings: FC = () => {
  const { t } = useTranslation();
  const service: SecuritySettingsService = new SecuritySettingsServiceApi();

  const [emailDomainAllowList, setEmailDomainAllowList] = useState<string[]>(
    []
  );
  const [loginIpWhiteList, setLoginIpWhiteList] = useState<string[]>([]);
  const [forceEmailTwoFactorAuth, setForceEmailTwoFactorAuth] =
    useState<boolean>(false);
  const [azureTenantID, setAzureTenantID] = useState<string>(null);
  const [isAzureIntegration, setIsAzureIntegration] = useState<boolean>(false);

  const [initialSecuritySettings, setInitialSecuritySettings] =
    useState<SecuritySettingsData>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** values to alert */
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [submitText, setSubmitText] = useState<string>('');
  const [alertText, setAlertText] = useState<string>('');
  const [alertSubmitFunction, setAlertSubmitFunction] = useState<() => void>();

  const saveSubmit = () => {
    setAlertText(`${t('alerts.userLoginIPWhiteListSaveSubmit')}`);
    setSubmitText(t('common.save'));
    setAlertSubmitFunction(() => save);
    setIsAlertOpen(true);
  };

  const saveButtonFunction = () => {
    if (loginIpWhiteList?.length === 0) {
      return save();
    }
    const isLoginIpWhiteListChanged =
      hash(loginIpWhiteList) !==
      hash(initialSecuritySettings?.loginIpWhiteList);
    if (isLoginIpWhiteListChanged) {
      return saveSubmit();
    }
    return save();
  };

  async function fetchData() {
    try {
      const response = await service.fetchData();

      setEmailDomainAllowList(response?.emailDomainAllowList);
      setLoginIpWhiteList(response?.loginIpWhiteList);
      setForceEmailTwoFactorAuth(response?.forceEmailTwoFactorAuth);
      setIsAzureIntegration(response?.azureIntegration || false);
      setAzureTenantID(response?.azureTenantID);

      setInitialSecuritySettings({
        ...response,
        forceEmailTwoFactorAuth: response?.forceEmailTwoFactorAuth || false,
        azureIntegration: response?.azureIntegration,
        azureTenantID: response?.azureTenantID
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function save() {
    setIsLoading(true);

    try {
      await service.save({
        emailDomainAllowList: emailDomainAllowList,
        loginIpWhiteList: loginIpWhiteList,
        forceEmailTwoFactorAuth: forceEmailTwoFactorAuth
      });
      setInitialSecuritySettings({
        emailDomainAllowList,
        loginIpWhiteList,
        forceEmailTwoFactorAuth,
        azureIntegration: isAzureIntegration,
        azureTenantID
      });

      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  const isSettingsChanged = useMemo(() => {
    if (!initialSecuritySettings) {
      return false;
    }
    const isEmailDomainAllowListChanged =
      hash(emailDomainAllowList) !==
      hash(initialSecuritySettings?.emailDomainAllowList);
    const isLoginIpWhiteListChanged =
      hash(loginIpWhiteList) !==
      hash(initialSecuritySettings?.loginIpWhiteList);
    const isForceEmailTwoFactorAuthChanged =
      forceEmailTwoFactorAuth !==
      initialSecuritySettings?.forceEmailTwoFactorAuth;

    return (
      isEmailDomainAllowListChanged ||
      isLoginIpWhiteListChanged ||
      isForceEmailTwoFactorAuthChanged
    );
  }, [
    loginIpWhiteList,
    emailDomainAllowList,
    forceEmailTwoFactorAuth,
    initialSecuritySettings
  ]);

  useEffect(() => {
    fetchData();
  }, []);

  /** Sidemenu */
  const sidemenuContext: SidemenuContextData = useContext(SidemenuContext);

  useEffect(() => {
    sidemenuContext.setActiveMenu(
      ORGANIZATION_SIDEMENUS.ORGANIZATION_SECURITY_SETTINGS
    );
  }, [sidemenuContext]);

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={10}>
          <Col col={9} className={styles.heading}>
            <Typography element="div" variant="h2">
              {t('organizations.securitySettings')}
            </Typography>

            <div className={styles.buttons}>
              <Button
                type="third"
                onClick={saveButtonFunction}
                disabled={!isSettingsChanged}
                isLoading={isLoading}
              >
                {t('common.save')}
              </Button>
            </div>
          </Col>
        </Col>
      </Row>

      <Row cols={10}>
        <Col col={9} md={6}>
          <form onSubmit={submitForm}>
            <Typography variant={'h5'} className={offsets['pb-40']}>
              Authentication settings
            </Typography>
            <div className={cn(display['d-flex'], offsets['pb-40'])}>
              <Typography
                variant={'body-1'}
                element={'div'}
                className={offsets['mr-12']}
              >
                Enable 2FA (via email)
              </Typography>
              <Toggle
                label={'Enable 2FA (via email)'}
                onChange={setForceEmailTwoFactorAuth}
                disabled={isLoading}
                checked={forceEmailTwoFactorAuth}
              />
            </div>

            <div className={cn(display['d-flex'], flex['align-items-center'])}>
              <Typography
                variant={'body-1'}
                element={'div'}
                className={offsets['mr-12']}
              >
                Enable AzureAD
              </Typography>
              <Toggle
                label={'Enable AzureAD'}
                onChange={setIsAzureIntegration}
                disabled
                checked={isAzureIntegration}
              />

              {!isAzureIntegration && (
                <Tooltip
                  className={offsets['ml-20']}
                  content={t('alerts.azureADIntegration')}
                >
                  <Icon name={'info'} size={'s'} />
                </Tooltip>
              )}
            </div>

            {isAzureIntegration && (
              <Input
                disabled
                className={styles['azure-input']}
                required={true}
                value={azureTenantID}
                onChange={(value) => setAzureTenantID(String(value))}
                label={t('Azure tenant ID')}
              />
            )}

            <div
              className={cn(styles.grid, offsets['pb-80'], offsets['pt-80'])}
            >
              <div className={styles['domain-list']}>
                <ListEditor
                  regex={/^\w+([.-]?\w+)*(\.\w{2,3})+$/}
                  title={t('organizations.userEmailDomainAllowList')}
                  label={t('organizations.addUserEmailDomainAllowList')}
                  items={emailDomainAllowList}
                  setItems={setEmailDomainAllowList}
                  alreadyExistText={t('validation.emailDomainAlreadyExist')}
                  notValidText={t('validation.emailDomainHasInvalidFormat')}
                />
                <Tooltip
                  className={styles['domain-list-tooltip']}
                  content={t('validation.listOfAllowedDomainNames')}
                >
                  <Icon name={'info'} size={'s'} />
                </Tooltip>
              </div>

              <ListEditor
                regex={
                  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
                }
                title={t('organizations.userLoginIpWhiteList')}
                label={t('organizations.addUserLoginIpWhiteList')}
                items={loginIpWhiteList}
                setItems={setLoginIpWhiteList}
                alreadyExistText={t('validation.ipAddressAlreadyExist')}
                notValidText={t('validation.ipHasInvalidFormat')}
              />
            </div>
          </form>
        </Col>
      </Row>
      <Alert
        isOpen={isAlertOpen}
        onSubmit={() => alertSubmitFunction()}
        onClose={() => setIsAlertOpen(false)}
        cancelText={t('common.cancel')}
        submitText={submitText}
      >
        <div dangerouslySetInnerHTML={{ __html: alertText }} />
      </Alert>
    </Fragment>
  );
};

SecuritySettings.displayName = 'SecuritySettings';
