import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import styles from './Users.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Alert,
  Button,
  changeSortDirection,
  Col,
  common,
  EmptyState,
  Filter,
  offsets,
  openStatusNotification,
  Row,
  Search,
  SortDirection,
  SystemInfographics,
  Table,
  TableAction,
  TableFilter,
  TableFilterGroup,
  TableRow,
  Typography
} from '@xq/ui-kit';
import { User, UsersData, UsersPageData, UserStatistics } from './dataTypes';
import { UsersService, UsersServiceApi } from './users-service';
import { useDebounce } from '@hooks';
import { config } from '@config';
import {
  checkNoMoreData,
  getStatusNotificationTranslations,
  mapFiltersFromUIToGateway
} from '@services';
import { getRouteUrl, ROUTES } from '@router';
import { ListMetaInfo, ListParams, TableFilterValues } from 'interfaces';
import { SORT_DIRECTIONS } from '@constants';
import {
  createUserColumns,
  createUserRows,
  mapColumnKey
} from '@pages/Users/Users/utils';
import cn from 'classnames';

export const Users: FC = () => {
  const service: UsersService = new UsersServiceApi();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [usersMeta, setUsersMeta] = useState<ListMetaInfo>(null);
  const [statistics, setStatistics] = useState<UserStatistics>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get('search') || ''
  );

  const [filters, setFilters] = useState<TableFilter[]>([]);
  const [filterGroups, setFilterGroups] = useState<TableFilterGroup[]>([]);

  const debouncedSearchValue = useDebounce(searchValue, config.debounceDelay);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<SortDirection>(
    SORT_DIRECTIONS.ASC as SortDirection
  );
  const [sortBy, setSortBy] = useState<string>('fullname');
  const searchBy = 'fullname';
  const [noMoreData, setNoMoreData] = useState<boolean>(false);

  /** values to alert */
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [submitText, setSubmitText] = useState<string>('');
  const [alertText, setAlertText] = useState<string>('');
  const [alertSubmitFunction, setAlertSubmitFunction] = useState<() => void>();
  const [isUsersLoading, setIsUsersLoading] = useState<boolean>(false);

  const getQueryParams = (page?: number): ListParams => {
    return {
      searchValue: String(debouncedSearchValue),
      sortOrder: sortOrder,
      sortBy: mapColumnKey(sortBy),
      page: page || usersMeta?.currentPage,
      filters: mapFiltersFromUIToGateway(filters)
    };
  };

  const deleteUserSubmit = (user: TableRow) => {
    const userInfo = users.find((el) => el.uuid === user.id);
    setAlertText(
      `${t('alerts.areYouSureYouWantToDelete')} <b>${userInfo?.email}</b>?`
    );
    setSubmitText(t('common.delete'));
    setAlertSubmitFunction(() => () => deleteUser(user));
    setIsAlertOpen(true);
  };

  const blockUserSubmit = (user: TableRow) => {
    const userInfo = users.find((el) => el.uuid === user.id);
    setSubmitText(t('common.block'));
    setAlertText(
      `${t('alerts.areYouSureYouWantToBlock')} <b>${userInfo?.email}</b>?`
    );
    setAlertSubmitFunction(() => () => blockUser(user));
    setIsAlertOpen(true);
  };

  const unblockUserSubmit = (user: TableRow) => {
    const userInfo = users.find((el) => el.uuid === user.id);
    setSubmitText(t('common.unblock'));
    setAlertText(
      `${t('alerts.areYouSureYouWantToUnblock')} <b>${userInfo?.email}</b>?`
    );
    setAlertSubmitFunction(() => () => unblockUser(user));
    setIsAlertOpen(true);
  };

  async function deleteUser(user: TableRow) {
    try {
      await service.deleteUser(user.id);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });
      const userToDelete = users.find((el) => user.id === el.uuid);
      const newUsers = users.filter((el) => user.id !== el.uuid);
      setUsers(newUsers);
      setStatistics({
        totalUsers: --statistics.totalUsers,
        activeUsers: userToDelete?.isBlocked
          ? statistics.activeUsers
          : --statistics.activeUsers,
        administrators: userToDelete?.isOrganizationAdmin
          ? --statistics.administrators
          : statistics.administrators,
        externalUsers: userToDelete?.isExternal
          ? --statistics.externalUsers
          : statistics.externalUsers
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function blockUser(user: TableRow) {
    try {
      await service.blockUser(user.id);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });
      const newUsers = users?.map((el) => {
        if (el.uuid === user.id) {
          el.isBlocked = !el.isBlocked;
        }
        return el;
      });
      setUsers(newUsers);
      setStatistics({
        ...statistics,
        activeUsers: --statistics.activeUsers
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function unblockUser(user: TableRow) {
    try {
      await service.unblockUser(user.id);
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200
      });
      const newUsers = users?.map((el) => {
        if (el.uuid === user.id) {
          el.isBlocked = !el.isBlocked;
          el.blockReason = null;
        }
        return el;
      });
      setUsers(newUsers);
      setStatistics({
        ...statistics,
        activeUsers: ++statistics.activeUsers
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  const editUser = (userId: string) => {
    navigate(
      getRouteUrl(ROUTES.USERS.EDIT_USER, {
        userId: userId
      })
    );
  };

  const rows = useMemo(() => {
    return createUserRows(t, users);
  }, [users]);

  const columns = createUserColumns(t);

  const actions: TableAction[] = [
    {
      key: 'reset password',
      title: t('common.resetPassword'),
      icon: 'key',
      onClick: resetPassword
    },
    {
      key: 'edit',
      title: t('common.edit'),
      icon: 'tool',
      onClick: (row) => editUser(row.id)
    },
    {
      key: 'delete',
      title: t('common.delete'),
      icon: 'trash',
      onClick: deleteUserSubmit
    },
    {
      key: 'block',
      title: t('common.block'),
      icon: 'lock',
      onClick: blockUserSubmit
    },
    {
      key: 'unblock',
      title: t('common.unblock'),
      icon: 'unlock',
      onClick: unblockUserSubmit
    }
  ];

  async function resetPassword(row: TableRow) {
    try {
      const user = users?.find((el) => el?.uuid === row.id);
      await service.resetPassword(user?.email);

      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: (
          <span>
            {t('notifications.resetMailSentPart1')}{' '}
            <strong>{user?.email}</strong>{' '}
            {t('notifications.resetMailSentPart2')}
          </span>
        )
      });
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  const filterActionsForRow = (actions: TableAction[], row: TableRow) => {
    let newActions = [...actions];
    const user = users?.find((el) => el.uuid === row.id);

    if (!user) {
      return actions;
    }

    if (user?.isBlocked) {
      newActions = newActions.filter((action) => action.key !== 'block');
      newActions = newActions.map((action) =>
        action.key === 'reset password' ? { ...action, disabled: true } : action
      );
    }

    if (!user.isBlocked) {
      newActions = newActions.filter((action) => action.key !== 'unblock');
    }

    if (user.fromAzure) {
      newActions = newActions.map((action) =>
        action.key === 'reset password' ? { ...action, disabled: true } : action
      );
    }

    return newActions;
  };

  async function fetchUsers() {
    const queryParams = getQueryParams(1);

    setIsUsersLoading(true);
    try {
      const result: UsersData = await service.fetchUsers(queryParams);
      const hasResults = !result.items || result?.items?.length === 0;
      setNoResults(hasResults);
      setUsers(result?.items);
      checkNoMoreData(
        result?.items?.length,
        result?.meta?.totalItems,
        setNoMoreData
      );
      setUsersMeta(result?.meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsUsersLoading(false);
    }
  }

  async function fetchData() {
    try {
      setIsLoading(true);
      const result: UsersPageData = await service.fetchData();
      setStatistics(result?.statistic);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchFilters() {
    try {
      const filterValues: TableFilterValues = await service.fetchFilters();
      setFilters(filterValues.filters);
      setFilterGroups(filterValues.groups);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  function updateSortParams(key: string) {
    let updatedSortBy = sortBy;
    let updatedSortOrder = sortOrder;

    if (key === sortBy) {
      updatedSortOrder = changeSortDirection(sortOrder);
      setSortOrder(updatedSortOrder);
    } else {
      updatedSortBy = key;
      setSortBy(updatedSortBy);
    }
  }

  const createUser = () => {
    navigate(getRouteUrl(ROUTES.USERS.CREATE_USER));
  };

  async function loadMoreUsers() {
    try {
      if (noMoreData) {
        return;
      }

      const queryParams = getQueryParams(++usersMeta.currentPage);

      const res = await service.fetchUsers(queryParams);
      const { items } = res;
      checkNoMoreData(items?.length, res?.meta?.totalItems, setNoMoreData);
      setUsers([...users, ...items]);
      setUsersMeta(res?.meta);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  useEffect(() => {
    if (debouncedSearchValue) {
      setSearchParams({ search: searchValue });
    } else {
      setSearchParams({});
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    fetchUsers();
  }, [sortOrder, sortBy, debouncedSearchValue, searchBy, filters]);

  useEffect(() => {
    fetchData();
    fetchFilters();
  }, []);

  return (
    <Fragment>
      <Row cols={10}>
        <Col col={9} className={'heading'}>
          <Typography element="div" variant="h2">
            {t('common.users')}
          </Typography>
          {users?.length > 0 && (
            <Button type="third" icon="user-plus" onClick={createUser}>
              {t('common.create')}
            </Button>
          )}
        </Col>

        {statistics?.totalUsers > 0 && (
          <Fragment>
            <Col col={9} className={styles.statistics}>
              <SystemInfographics
                isLoading={isLoading}
                className={offsets['mr-40']}
                heading={t('organizations.totalUsers')}
                content={String(statistics?.totalUsers)}
              />
              <SystemInfographics
                isLoading={isLoading}
                className={offsets['mr-40']}
                heading={t('organizations.activeUsers')}
                content={String(statistics?.activeUsers)}
              />
              <SystemInfographics
                isLoading={isLoading}
                className={offsets['mr-40']}
                heading={t('organizations.administrators')}
                content={String(statistics?.administrators)}
              />
              <SystemInfographics
                isLoading={isLoading}
                heading={t('organizations.externalUser')}
                content={String(statistics?.externalUsers)}
              />
            </Col>

            <Col
              col={9}
              className={cn(common['container-queries-wrap'], offsets['mb-20'])}
            >
              <div className={common['table-filters-wrap']}>
                <Search
                  className={common['table-search']}
                  placeholder={t('common.searchByName')}
                  value={searchValue}
                  onChange={setSearchValue}
                />
                <Filter
                  className={common['table-filter']}
                  filters={filters}
                  setFilters={setFilters}
                  filterGroups={filterGroups}
                />
              </div>
            </Col>

            <Col col={10} md={9}>
              <Table
                rows={rows}
                columns={columns}
                actions={actions}
                filterActionsForRow={filterActionsForRow}
                noMoreData={noMoreData}
                noMoreItemsText={t('common.noMoreItems')}
                onLoading={loadMoreUsers}
                maxHeight="640px"
                minWidth={800}
                onSort={updateSortParams}
                sortOrder={sortOrder}
                sortBy={sortBy}
                noResults={noResults}
                noResultsText={t('common.noResults')}
              />
            </Col>
          </Fragment>
        )}

        <Col col={9}>
          {!isUsersLoading && statistics?.totalUsers === 0 && (
            <EmptyState
              heading={t('uiKit.oopsItIsEmpty')}
              description={t('alerts.looksLikeTheOrganizationHasNoLicenses')}
              onClick={createUser}
              buttonText={t('common.create')}
              buttonIcon={'user-plus'}
              isCentered={true}
            />
          )}
        </Col>
      </Row>

      <Alert
        isOpen={isAlertOpen}
        onSubmit={() => alertSubmitFunction()}
        onClose={() => setIsAlertOpen(false)}
        cancelText={t('common.cancel')}
        submitText={submitText}
      >
        <div dangerouslySetInnerHTML={{ __html: alertText }} />
      </Alert>
    </Fragment>
  );
};

Users.displayName = 'Users';
