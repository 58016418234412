import { adminApiConfiguration, handleBackendError, wait } from '@services';
import { SecuritySettingsData } from './dataTypes';
import { SecuritySettingsPageApi } from '@xq/admin-panel-gateway-frontend-client';

export interface SecuritySettingsService {
  fetchData(): Promise<SecuritySettingsData>;

  save(settings: SecuritySettingsData): Promise<void>;
}

const securitySettingsMock: SecuritySettingsData = {
  emailDomainAllowList: ['2ns.com'],
  loginIpWhiteList: ['251.123.12.3', '129.209.29.180'],
  forceEmailTwoFactorAuth: false,
  azureTenantID: null,
  azureIntegration: false
};

const securitySettingsGateway = new SecuritySettingsPageApi(
  adminApiConfiguration
);

export class SecuritySettingsServiceApi implements SecuritySettingsService {
  async fetchData(): Promise<SecuritySettingsData> {
    try {
      const response =
        await securitySettingsGateway.securitySettingsPageControllerGetSecuritySettings();

      return {
        emailDomainAllowList: response?.allowedDomainList,
        loginIpWhiteList: response?.allowedIPList,
        forceEmailTwoFactorAuth: response?.forceEmailTwoFactorAuth,
        azureIntegration: response?.azureIntegration,
        azureTenantID: response?.azureTenantID
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(settings: SecuritySettingsData): Promise<void> {
    try {
      await securitySettingsGateway.securitySettingsPageControllerSaveSecuritySettings(
        {
          organizationSecuritySettingsDTORequest: {
            allowedIPList: settings?.loginIpWhiteList,
            allowedDomainList: settings?.emailDomainAllowList,
            forceEmailTwoFactorAuth: settings?.forceEmailTwoFactorAuth
          }
        }
      );
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}

export class SecuritySettingsServiceMock implements SecuritySettingsService {
  async fetchData(): Promise<SecuritySettingsData> {
    try {
      await wait(1000);
      return securitySettingsMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async save(settings: SecuritySettingsData): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.save.name);
    }
  }
}
