import {
  CheckboxTableFilter,
  RadioTableFilter,
  TableFilter,
  ToggleTableFilter
} from '@xq/ui-kit';
import {
  CommonFilterDTOResponse,
  GatewayFilterDTORequest
} from '@xq/admin-panel-gateway-frontend-client';

export const mapFiltersFromGatewayToUI = (
  filters: CommonFilterDTOResponse[]
) => {
  const mappedFilters: TableFilter[] = filters?.map((filter) => {
    let updatedFilter;

    if (filter.checkbox) {
      updatedFilter = {
        id: filter.filterId,
        groupId: filter.groupId,
        type: 'checkbox',
        label: filter.checkbox.label,
        value: filter.checkbox.value
      } as CheckboxTableFilter;
    }

    if (filter.toggle) {
      updatedFilter = {
        id: filter.filterId,
        groupId: filter.groupId,
        type: 'toggle',
        label: filter.checkbox.label,
        value: filter.checkbox.value
      } as ToggleTableFilter;
    }

    if (filter.radio) {
      updatedFilter = {
        id: filter.filterId,
        groupId: filter.groupId,
        type: 'radio',
        items: filter.radio.items,
        value: filter.radio.value
      } as RadioTableFilter;
    }

    return updatedFilter;
  });

  return mappedFilters;
};

export const mapFiltersFromUIToGateway = (
  filters: TableFilter[]
): GatewayFilterDTORequest[] => {
  const mappedFilters: GatewayFilterDTORequest[] =
    filters
      ?.filter((filter) => filter.value)
      ?.map((filter) => {
        const updatedFilter = {
          filterId: filter.id,
          value: Object(filter.value)
        };

        return updatedFilter;
      }) || [];

  return mappedFilters;
};
