import { handleBackendError, wait, adminApiConfiguration } from '@services';
import { ContactPerson } from '@pages/Organizations/ContactPersons/dataTypes';
import { ContactPersonListPageApi } from '@xq/admin-panel-gateway-frontend-client';

interface ContactPersonsData {
  contactPersons: ContactPerson[];
}

export interface ContactPersonsService {
  fetchData(): Promise<ContactPersonsData>;
  deleteContactPerson(contactPersonUuid: string): Promise<void>;
}

const contactPersonsGateway = new ContactPersonListPageApi(
  adminApiConfiguration
);

export class ContactPersonsService implements ContactPersonsService {
  async fetchData(): Promise<ContactPersonsData> {
    try {
      return await contactPersonsGateway.contactPersonListPageControllerGetPageData();
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async deleteContactPerson(contactPersonUuid: string): Promise<void> {
    try {
      await contactPersonsGateway.contactPersonListPageControllerDeleteContactPerson(
        {
          organizationContactPersonUuid: contactPersonUuid
        }
      );
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.deleteContactPerson.name
      );
    }
  }
}

const contactPersonsMock: ContactPerson[] = [
  {
    uuid: '1',
    position: 'CIO',
    name: 'Horatio Morrison',
    phone: '+ 3 219 987 11 11',
    email: 'horatio.morrison@2ns.com',
    comment: "Managing IT staff and organization's software development needs"
  },
  {
    uuid: '2',
    position: 'Senior manager',
    name: 'Shana Brooks',
    phone: '+ 3 217 467 10 90',
    email: 'shana.brooks@2ns.com',
    comment: 'Managing bills and add new users'
  }
];

const contactPersonData: ContactPersonsData = {
  contactPersons: contactPersonsMock
};

export class ContactPersonsServiceMock implements ContactPersonsService {
  async fetchData(): Promise<ContactPersonsData> {
    try {
      await wait(1000);
      return {
        ...contactPersonData
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }

  async deleteContactPerson(contactPersonUuid: string): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.deleteContactPerson.name
      );
    }
  }
}
