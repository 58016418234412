import packageJson from '../../package.json';
import {
  AuthApi,
  Configuration as IamConfiguration
} from '@xq/iam-frontend-client';
import { config } from '@config';
import { Configuration as AdminConfiguration } from '@xq/admin-panel-gateway-frontend-client';
import { ConfigurationParameters } from '@xq/iam-frontend-client';

export interface AppVersion {
  version: string;
  name: string;
}

export const appVersion: AppVersion = {
  version: packageJson.version,
  name: packageJson.name
};

const getConfiguration = (basePath: string): ConfigurationParameters => {
  return {
    basePath: basePath,
    credentials: 'include',
    headers: {
      'xq-app-version': appVersion.version,
      'xq-app-name': appVersion.name
    }
  };
};

export const iamGateway = new AuthApi(
  new IamConfiguration(getConfiguration(config.apiUrl))
);

/** Usage example:
 *** const adminUsersGateway = new LayoutApi(adminApiConfiguration)
 **/

export const adminApiConfiguration = new AdminConfiguration(
  getConfiguration(`${config.apiUrl}/admin-panel`)
);
