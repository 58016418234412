import { OrganizationName } from '../interfaces';
import { getRouteUrl, ROUTES } from '@router';
import { MenuItem } from '@xq/ui-kit';
import { TFunction } from 'i18next';
import { SidemenuLayoutLicense } from 'layouts/SidemenuLayout/sidemenu-layout-service';

export const ORGANIZATION_SIDEMENUS = {
  ORGANIZATION: 'organization',
  ORGANIZATION_INFO: 'organization-info',
  ORGANIZATION_USERS: 'organization-users',
  ORGANIZATION_LICENSES: 'organization-licenses',
  FUNDY: 'fundy',
  MODEL_TREE_INVESTMENT: 'model-tree-investment',
  ORGANIZATION_INVOICES: 'organization-invoices',
  ORGANIZATION_CONTACT_PERSONS: 'organization-contact-persons',
  ORGANIZATION_DATABASE_CONNECTION: 'organization-database-connection',
  ORGANIZATION_SECURITY_SETTINGS: 'organization-security-settings',
  ORGANIZATION_ACTIVITY_LOG: 'organization-activity-log'
};

/* todo add other routes */
/* todo replace to {params} */
/* todo replace ORGANIZATION_SIDEMENUS to ROUTES objects */
export const generateOrganizationSidemenu = (
  t: TFunction,
  organization?: OrganizationName
): MenuItem[] => [
  {
    key: ORGANIZATION_SIDEMENUS.ORGANIZATION,
    route: getRouteUrl(ROUTES.ORGANIZATION.ORGANIZATION),
    title: organization?.name ? organization?.name : '',
    icon: 'home'
  },
  {
    key: ORGANIZATION_SIDEMENUS.ORGANIZATION_CONTACT_PERSONS,
    route: getRouteUrl(ROUTES.ORGANIZATION.CONTACT_PERSONS),
    title: t(ROUTES.ORGANIZATION.CONTACT_PERSONS),
    icon: 'user-check'
  },
  {
    key: ORGANIZATION_SIDEMENUS.ORGANIZATION_SECURITY_SETTINGS,
    route: getRouteUrl(ROUTES.ORGANIZATION.SECURITY_SETTINGS),
    title: t(ROUTES.ORGANIZATION.SECURITY_SETTINGS),
    icon: 'shield'
  }
];

export const generateLicensesSidemenu = (
  t: TFunction,
  licenses?: SidemenuLayoutLicense[]
): MenuItem[] => {
  if (licenses?.length > 0) {
    return licenses?.map((license) => {
      return {
        key: `licenses-${license?.uuid}`,
        route: getRouteUrl(ROUTES.LICENSES.LICENSE, {
          licenseId: license?.uuid
        }),
        title: license?.title,
        //todo use correct icon name based on license
        icon: 'licenses-modeltree'
      };
    });
  }

  return [
    {
      key: ROUTES.LICENSES.LICENSES,
      route: getRouteUrl(ROUTES.LICENSES.LICENSES),
      title: t(ROUTES.LICENSES.LICENSES),
      icon: 'licenses'
    }
  ];
};
