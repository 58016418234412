import { adminApiConfiguration, handleBackendError, wait } from '@services';
import { OrganizationData } from '@pages/Organizations/Organization/dataTypes';
import { TFunction } from 'i18next';
import { OrganizationsInfoPageApi } from '@xq/admin-panel-gateway-frontend-client';
import {
  convertBillingFrequencyToText,
  convertPaymentMethodToText
} from '@xq/ui-kit';

export interface OrganizationService {
  fetchData(t: TFunction): Promise<OrganizationData>;
}

const organizationsInfoGateway = new OrganizationsInfoPageApi(
  adminApiConfiguration
);

export class OrganizationService implements OrganizationService {
  async fetchData(t: TFunction): Promise<OrganizationData> {
    try {
      const response =
        await organizationsInfoGateway.organizationInfoPageControllerGetPageData();
      return {
        ...response,
        licenses: response?.licenseInfo?.map((el) => {
          return {
            uuid: el?.uuid,
            license: el?.licenseName,
            activeUsers: el?.activeUsers,
            invoicing: convertBillingFrequencyToText(el?.billingFrequency),
            paymentMethod: convertPaymentMethodToText(el?.paymentMethod),
            activationDate: el?.createdAt,
            billingStartDate: el?.billingStartDate,
            expiryDate: el?.expireAt,
            isTrial: el?.isTrial
          };
        })
      };
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }
}

const organizationMock: OrganizationData = {
  organizationInfo: {
    name: '2NS',
    description: 'Description',
    countryIso3: 'FIN',
    currencyIso3: 'EUR',
    agreementId: '1234',
    agreementDate: new Date(2020, 21, 2)
  },
  invoicesState: {
    currencyIso3: 'EUR',
    lastInvoiceDate: new Date(2022, 1, 1),
    nextInvoiceDate: new Date(2023, 1, 1),
    lastPayment: 10000,
    nextPayment: 12000
  },
  licenses: [
    {
      uuid: '123',
      license: 'ModelTree Valuation',
      activeUsers: 10,
      invoicing: 'Monthly',
      paymentMethod: 'Prepayment',
      activationDate: new Date('2020-02-01T08:00:00.000z'),
      billingStartDate: new Date('2020-11-01T08:00:00.000z')
    },
    {
      uuid: '124',
      license: 'Fundy',
      activeUsers: 33,
      invoicing: 'Annually',
      paymentMethod: 'Prepayment',
      activationDate: new Date('2020-03-01T08:00:00.000z'),
      billingStartDate: new Date('2022-09-09T08:00:00.000z'),
      expiryDate: new Date('2023-03-03T08:00:00.000z'),
      isTrial: true
    }
  ],
  userStatistic: {
    totalUsers: 120,
    activeUsers: 98,
    administrators: 5
  },
  contactPersons: [
    {
      uuid: '1',
      position: 'CIO',
      name: 'Horatio Morrison',
      phone: '+ 3 219 987 11 11',
      email: 'horatio.morrison@2ns.com',
      comment: "Managing IT staff and organization's software development needs"
    },
    {
      uuid: '2',
      position: 'Senior manager',
      name: 'Shana Brooks',
      phone: '+ 3 217 467 10 90',
      email: 'shana.brooks@2ns.com',
      comment: 'Managing bills and add new users'
    }
  ]
};

export class OrganizationServiceMock implements OrganizationService {
  async fetchData(t: TFunction): Promise<OrganizationData> {
    try {
      await wait(1000);
      return organizationMock;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.fetchData.name
      );
    }
  }
}
