import { TFunction } from 'i18next';
import { OpenStatusNotificationParams } from '@xq/ui-kit';

export const handleTranslationErrorFromStatus = (
  t: TFunction,
  params: OpenStatusNotificationParams
): string => {
  switch (params?.error.toString()) {
    case 'USER_EMAIL_ALREADY_EXISTS': {
      return t('notifications.userIsExist');
    }
    case 'USER_EMAIL_DOMAIN_IS_FORBIDDEN': {
      return t('notifications.domainIsForbidden');
    }
    default: {
      break;
    }
  }
};
