import React, { FC } from 'react';
import styles from '../../License.module.scss';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  offsets,
  SystemInfographics,
  formatDate
} from '@xq/ui-kit';
import { LicensePageData } from '@pages';
import { DASH } from '@constants';

interface LicenseSystemInfographicsProps {
  information: LicensePageData;
  isLoading: boolean;
  isMD: boolean;
}

export const LicenseSystemInfographics: FC<LicenseSystemInfographicsProps> = (
  props
) => {
  const { t } = useTranslation();
  const { information, isLoading } = props;

  return (
    <div className={styles.statistics}>
      <SystemInfographics
        isLoading={isLoading}
        className={offsets['mr-40']}
        heading={t('organizations.activationDate')}
        content={
          information?.createdAt ? formatDate(information?.createdAt) : DASH
        }
        isError={!isLoading && !information?.createdAt}
      />

      {information?.isTrial && (
        <SystemInfographics
          isLoading={isLoading}
          className={offsets['mr-40']}
          heading={t('uiKit.expiryDate')}
          content={
            information?.expireAt ? formatDate(information?.expireAt) : DASH
          }
          isError={!isLoading && information?.isExpired}
        />
      )}

      <SystemInfographics
        isLoading={isLoading}
        className={offsets['mr-40']}
        heading={t('organizations.activeUsers')}
        content={
          information?.activeUsers ? String(information?.activeUsers) : DASH
        }
      />

      {information?.licenseFeatures &&
        information?.licenseFeatures.length > 0 && (
          <SystemInfographics
            isLoading={isLoading}
            heading={t('common.licenseFeatures')}
          >
            <div className={styles.features}>
              {information?.licenseFeatures?.map((feature, key) => {
                return (
                  <Typography
                    key={key}
                    element={'div'}
                    variant="body-4"
                    className={offsets['mr-16']}
                  >
                    {feature}
                  </Typography>
                );
              })}
            </div>
          </SystemInfographics>
        )}
    </div>
  );
};

LicenseSystemInfographics.displayName = 'LicenseSystemInfographics';
